<template>
    <div class="auth-wrapper auth-v1">

        <v-card v-if="state == 1"
                class="auth-inner main-container-width">
            <app-header :details="selectedCard"></app-header>
            <div v-if="showAlert">
                <alert :message="alertMessage"
                       :type="alertType"></alert>

            </div>
            <v-form class="multi-col-validation px-lg-10 px-md-8 px-sm-6 px-4"
                    ref="loginForm"
                    lazy-validation
                    v-model="valid">
                <div>
                    <div class="pt-5">
                        <div style="margin-left: -29px;margin-right: -29px;margin-bottom:13px">
                            <BackAction :text="text"
                                        :output="output" />
                        </div>
                        <p class="text-lg-2xl text-xl font-weight-semibold text-black mt-2 mb-3 "
                           style="margin-bottom: 0;">
                            {{ $t(`Please fill in the below information for the Supplementary Card applicant`) }}

                        </p>
                    </div>
                    <v-row class="d-flex">
                        <v-col cols="12"
                               lg="8"
                               md="8"
                               sm="12">

                            <v-row>
                                <v-col cols="12"
                                       lg="4"
                                       md="4"
                                       sm="12"
                                       class="text-field mt-1 mb-1">
                                    <p class="font-weight-semibold text-color-black"
                                       style="font-size: 13px;">

                                        {{ $t(`Title`) }}
                                    </p>
                                    <v-select v-if="selectedLanguage == 'en'"
                                              outlined
                                              :items="titles"
                                              solo
                                              item-text="value"
                                              item-value="key"
                                              :rules="selectTitle"
                                              v-model="title"
                                              :label="$t('Select Title')">
                                        <template #message="{ message }">
                                            {{ $t(message) }}
                                        </template>
                                    </v-select>
                                    <v-select v-if="selectedLanguage == 'ar'"
                                              outlined
                                              :items="titlesAr"
                                              solo
                                              item-text="value"
                                              item-value="key"
                                              :rules="selectTitle"
                                              v-model="title"
                                              :label="$t('Select Title')">
                                        <template #message="{ message }">
                                            {{ $t(message) }}
                                        </template>
                                    </v-select>
                                </v-col>

                                <v-col cols="12"
                                       lg="4"
                                       md="4"
                                       sm="12"
                                       class="text-field mt-1 mb-1">
                                    <p class="font-weight-semibold text-color-black"
                                       style="font-size: 13px;">

                                        {{ $t(`First Name`) }}
                                    </p>
                                    <v-text-field v-model="firstName"
                                                  solo
                                                  :rules="name"
                                                  outlined
                                                  :label="$t('First Name')">
                                        <template #message="{ message }">
                                            {{ $t(message) }}
                                        </template>
                                    </v-text-field>
                                </v-col>

                                <v-col cols="12"
                                       lg="4"
                                       md="4"
                                       sm="12"
                                       class="text-field mt-1 mb-1">
                                    <p class="font-weight-semibold text-color-black"
                                       style="font-size: 13px;">

                                        {{ $t(`Last Name`) }}
                                    </p>
                                    <v-text-field v-model="lastName"
                                                  solo
                                                  outlined
                                                  :rules="lastname"
                                                  :label="$t('Last Name')">
                                        <template #message="{ message }">
                                            {{ $t(message) }}
                                        </template>
                                    </v-text-field>
                                </v-col>

                                <v-col cols="12"
                                       lg="4"
                                       md="4"
                                       sm="12"
                                       class="text-field">
                                    <span class="font-weight-semibold text-color-black"
                                          style="font-size: 13px;">

                                        {{ $t('Personal ID/Iqama number') }}
                                    </span>
                                    <v-text-field outlined
                                                  solo
                                                  v-model="idNumber"
                                                  :rules="number"
                                                  class="mt-3"
                                                  @input="validateIqama()"
                                                  :label="$t('Personal ID/Iqama number')">
                                        <template #message="{ message }">
                                            {{ $t(message) }}
                                        </template>
                                    </v-text-field>
                                </v-col>

                                <v-col cols="12"
                                       lg="4"
                                       md="4"
                                       sm="12"
                                       class="text-field ">
                                    <span class="font-weight-semibold text-color-black"
                                          style="font-size: 13px;">

                                        {{ $t('Nationality') }}
                                    </span>
                                    <v-autocomplete v-if="selectedLanguage == 'en'"
                                                    outlined
                                                    :items="allCountries"
                                                    solo
                                                    item-text="en"
                                                    item-value="en"
                                                    :rules="national"
                                                    v-model="nationality"
                                                    :label="$t('Select Nationality')"
                                                    @change="changeCountry"
                                                    class="mt-3">
                                        <template #message="{ message }">
                                            {{ $t(message) }}
                                        </template>
                                    </v-autocomplete>

                                    <v-autocomplete v-if="selectedLanguage == 'ar'"
                                                    outlined
                                                    :items="allCountries"
                                                    solo
                                                    item-text="ar"
                                                    item-value="en"
                                                    :rules="national"
                                                    v-model="nationality"
                                                    :label="$t('Select Nationality')"
                                                    @change="changeCountry"
                                                    class="mt-3">
                                        <template #message="{ message }">
                                            {{ $t(message) }}
                                        </template>
                                    </v-autocomplete>
                                </v-col>

                                <v-col cols="12"
                                       lg="4"
                                       md="4"
                                       sm="12"
                                       class="text-field ">
                                    <span class="font-weight-semibold"
                                          :class="{ 'text-color-black': countryShow === false }"
                                          style="font-size: 13px;">
                                        {{ $t('Passport Number') }}
                                    </span>
                                    <v-text-field v-model="passportNumber"
                                                  :disabled="countryShow"
                                                  outlined
                                                  ref="field"
                                                  :rules="passport"
                                                  :label="$t('Passport Number')"
                                                  solo
                                                  class="mt-3">
                                        <template #message="{ message }">
                                            {{ $t(message) }}
                                        </template>
                                    </v-text-field>
                                </v-col>

                                <v-col cols="12"
                                       lg="4"
                                       md="4"
                                       sm="12">
                                    <span class="font-weight-semibold text-color-black"
                                          style="font-size: 12px;">

                                        {{ $t('I want an additional Card for my') }}
                                    </span>
                                    <v-select v-if="selectedLanguage == 'en'"
                                              outlined
                                              :items="relations"
                                              solo
                                              item-text="name"
                                              item-value="key"
                                              :rules="card"
                                              v-model="additionalCard"
                                              :label="$t('Select Card')"
                                              @change="selectRelations"
                                              class="mt-3">
                                        <template #message="{ message }">
                                            {{ $t(message) }}
                                        </template>
                                    </v-select>
                                    <v-select v-if="selectedLanguage == 'ar'"
                                              outlined
                                              :items="relationsAr"
                                              solo
                                              item-text="name"
                                              item-value="key"
                                              :rules="card"
                                              v-model="additionalCard"
                                              :label="$t('Select Card')"
                                              @change="selectRelations"
                                              class="mt-3">
                                        <template #message="{ message }">
                                            {{ $t(message) }}
                                        </template>
                                    </v-select>

                                </v-col>

                                <v-col cols="12"
                                       lg="4"
                                       md="4"
                                       sm="12">
                                    <span class="font-weight-semibold text-color-black"
                                          style="font-size: 13px;">
                                        {{ $t('Gender') }}
                                    </span>
                                    <v-select v-if="selectedLanguage == 'en'"
                                              outlined
                                              :items="genders"
                                              solo
                                              item-text="value"
                                              item-value="id"
                                              :rules="genderRule"
                                              :label="$t('Select Gender')"
                                              v-model="gender"
                                              class="mt-3">
                                        <template #message="{ message }">
                                            {{ $t(message) }}
                                        </template>
                                    </v-select>
                                    <v-select v-if="selectedLanguage == 'ar'"
                                              outlined
                                              :items="gendersAr"
                                              solo
                                              item-text="value"
                                              item-value="id"
                                              :rules="genderRule"
                                              :label="$t('Select Gender')"
                                              v-model="gender"
                                              class="mt-3">
                                        <template #message="{ message }">
                                            {{ $t(message) }}
                                        </template>
                                    </v-select>
                                </v-col>

                                <v-col cols="12"
                                       lg="4"
                                       md="4"
                                       sm="12">
                                    <div class="font-weight-semibold text-color-black mb-4"
                                         style="font-size: 13px;">
                                        {{ $t('Date of Birth') }}
                                    </div>
                                    <v-menu v-model="menu1"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                            class="mt-1">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="date1"
                                                          outlined
                                                          solo
                                                          readonly
                                                          append-icon="mdi-calendar-month"
                                                          @click:append="on.click"
                                                          :rules="dateRule"
                                                          v-bind="attrs"
                                                          :label="$t('Date')"
                                                          v-on="on">
                                                <template #message="{ message }">
                                                    {{ $t(message) }}
                                                </template>
                                            </v-text-field>
                                        </template>
                                        <v-date-picker v-model="date1"
                                                       :active-picker.sync="activePicker"
                                                       :max="before16Years"
                                                       @input="menu1 = false"></v-date-picker>
                                    </v-menu>

                                </v-col>
                                <v-col cols="12"
                                       lg="4"
                                       md="4"
                                       sm="12"
                                       class="text-field top-space">
                                    <span class="font-weight-semibold text-color-black"
                                          style="font-size: 13px;">
                                        {{ $t('disability') }}
                                    </span>
                                    <!-- <v-checkbox class="mt-5"
                                                hide-details="auto"
                                                :label="MaxLimit == 0 ? ($t('No')) : ($t('Yes'))"
                                                v-model="MaxLimit"></v-checkbox> -->
                                    <v-radio-group v-model="MaxLimit"
                                                   row>
                                        <v-radio :label="$t('Yes')"
                                                 value="1"></v-radio>
                                        <v-radio :label="$t('No')"
                                                 value="0"></v-radio>
                                    </v-radio-group>

                                </v-col>
                                <v-col cols="12"
                                       lg="4"
                                       md="4"
                                       sm="12"
                                       class="text-field">
                                    <span class="font-weight-semibold text-color-black"
                                          style="font-size: 13px;">
                                        {{ $t('Email Address') }}
                                    </span>
                                    <v-text-field v-model="email"
                                                  outlined
                                                  solo
                                                  :rules="emailRule"
                                                  hide-details="auto"
                                                  :label="$t('Email Address')"
                                                  class="mt-3 mb-3">
                                        <template #message="{ message }">
                                            {{ $t(message) }}
                                        </template>
                                    </v-text-field>
                                </v-col>

                                <v-col cols="12"
                                       lg="4"
                                       md="4"
                                       sm="12"
                                       class="text-field top-space">
                                    <span class="font-weight-semibold text-color-black"
                                          style="font-size: 13px;">

                                        {{ $t('Mobile Number') }}
                                    </span>
                                    <v-text-field v-model="mobileNumber"
                                                  outlined
                                                  solo
                                                  :rules="mobile"
                                                  v-mask="'##########'"
                                                  :label="$t('Mobile Number')"
                                                  placeholder="05xxxxxxxx"
                                                  class="mt-3">
                                        <template #message="{ message }">
                                            {{ $t(message) }}
                                        </template>
                                    </v-text-field>

                                </v-col>

                            </v-row>
                        </v-col>

                        <v-col lg="4"
                               md="4"
                               sm="12">

                            <div class="security py-5 pa-4">

                                <p class="font-weight-semibold text-black"
                                   style="margin-bottom: 0;font-size: 18px !important;">
                                    {{ $t('How would you like to write your name on the Card?') }}
                                </p>
                                <p class="font-weight-semibold text-blue mt-4"
                                   style="margin-bottom: 0;font-size: 14px !important;">
                                    {{ $t('Name on the Card') }}
                                </p>

                                <v-row>
                                    <v-col cols="12"
                                           lg="12"
                                           md="12"
                                           sm="12"
                                           class="text-field mt-1 mb-1">

                                        <v-text-field v-model="firstName"
                                                      solo
                                                      :rules="name"
                                                      background-color="#f6f8fa"
                                                      outlined
                                                      :label="$t('First Name')">
                                            <template #message="{ message }">
                                                {{ $t(message) }}
                                            </template>
                                        </v-text-field>
                                    </v-col>

                                    <v-col cols="12"
                                           lg="12"
                                           md="12"
                                           sm="12"
                                           class="text-field mb-1">

                                        <v-text-field v-model="lastName"
                                                      solo
                                                      outlined
                                                      :rules="lastname"
                                                      background-color="#f6f8fa"
                                                      :label="$t('Last Name')">
                                            <template #message="{ message }">
                                                {{ $t(message) }}
                                            </template>
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <div class="mt-6 mb-1"
                                     style="width:220px;margin: 0 auto;">
                                    <v-img contain
                                           class="auth-3d-group overflow-hidden mt-3"
                                           :src="selectedCard.image">
                                    </v-img>
                                    <p v-if="firstName"
                                       :style="getStyle()"
                                       class="ml-5 show-cardname">
                                        {{ firstName }} {{ lastName }}
                                    </p>
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="12"
                               lg="8"
                               class="mt-3">
                            <v-row>
                                <v-col cols="12"
                                       lg="6"
                                       md="8"
                                       sm="12"
                                       class="image-upload">
                                    <span class="font-weight-semibold text-color-black"
                                          style="font-size: 13px;">
                                        {{ $t('Upload a copy of Supplementary ID') }}
                                    </span>

                                    <v-file-input ref="inputFile1"
                                                  outlined
                                                  solo
                                                  flat
                                                  id="fileUpload1"
                                                  v-model="supplementaryFile"
                                                  :rules="file1"
                                                  :placeholder="$t('Select File')"
                                                  class="mt-3"
                                                  hidden
                                                  accept="image/jpg, image/png, application/pdf"
                                                  @change="uploadImg1"
                                                  :append-icon="mdiUpload">
                                        <template #message="{ message }">
                                            {{ $t(message) }}
                                        </template>
                                        <template v-slot:selection="{ text }">
                                            <v-chip small
                                                    label
                                                    color="primary">
                                                {{ text }}
                                            </v-chip>
                                        </template>

                                    </v-file-input>

                                    <div class="text-center drag-border">
                                        <label for="fileInput"
                                               class="file-label">
                                            <img v-if="!image"
                                                 src="@/assets/images/logos/dragable.png"
                                                 alt="">
                                            <img v-if="image && image != 'pdf'"
                                                 class="img"
                                                 :src="image" />
                                            <img v-if="image && image == 'pdf'"
                                                 src="@/assets/images/logos/icons/file-icons/pdf.png"
                                                 alt="">
                                        </label>
                                        <p class="mt-4"
                                           style="font-size: 11px;">{{ $t('Max file size: 5MB | Format: png, jpg and pdf')
                                           }}</p>
                                    </div>

                                </v-col>

                                <v-col cols="12"
                                       lg="6"
                                       md="8"
                                       sm="12">
                                    <span v-if="getVisibleStatus()"
                                          class="font-weight-semibold text-color-black"
                                          style="font-size: 13px;">
                                        {{ isSaudiIqama ? $t(`Upload a copy of Family ID`) : $t(`Supplementary Copy of
                                        Passport`) }}
                                    </span>

                                    <v-file-input v-if="getVisibleStatus()"
                                                  ref="inputFile2"
                                                  outlined
                                                  solo
                                                  id="fileUpload2"
                                                  :placeholder="$t('Select File')"
                                                  v-model="familyId"
                                                  class="mt-3"
                                                  @change="uploadImg2"
                                                  accept="image/jpg, image/png, application/pdf"
                                                  hidden
                                                  :append-icon="mdiUpload">

                                        <template v-slot:selection="{ text }">
                                            <v-chip small
                                                    label
                                                    color="primary">
                                                {{ text }}
                                            </v-chip>
                                        </template>

                                    </v-file-input>
                                    <!-- </div> -->

                                    <div v-if="getVisibleStatus()"
                                         class="text-center drag-border">
                                        <label for="fileInput"
                                               class="file-label">
                                            <img v-if="!image2"
                                                 src="@/assets/images/logos/dragable.png"
                                                 alt="">
                                            <img v-if="image2 && image2 != 'pdf'"
                                                 class="img"
                                                 :src="image2" />
                                            <img v-if="image2 && image2 == 'pdf'"
                                                 src="@/assets/images/logos/icons/file-icons/pdf.png"
                                                 alt="">
                                        </label>
                                        <p class="mt-4"
                                           style="font-size: 11px;">{{ $t('Max file size: 5MB | Format: png, jpg and pdf')
                                           }}</p>
                                    </div>

                                </v-col>

                                <v-col cols="12"
                                       lg="8"
                                       md="8"
                                       sm="12">
                                    <v-btn color="primary"
                                           type="button"
                                           style="width: 100%"
                                           @click="submit"
                                           :disabled="isFormInvalid"
                                           x-large
                                           class=" py-3 mb-5">
                                        {{ $t('Submit_sup') }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
            </v-form>
        </v-card>
        <v-card v-if="state == 2"
                class="auth-inner main-container-width">
            <v-row class="pa-16 text-center success-message">
                <v-col lg="12"
                       class="text-center">
                    <div class="circle">
                        <img src="@/assets/images/Success.png"
                             alt=""
                             width="70px">
                    </div>
                    <h2 style="font-size: 20px;"
                        class="mt-5">
                        {{ $t(`All done! Your supplementary Card request has been submitted. An American Express
                        representative will contact you shortly.`) }}</h2>

                    <v-row class="pa-12">
                        <v-col lg="12"
                               class="text-center">
                            <v-btn class=""
                                   style="width: 100%;"
                                   color="primary"
                                   @click="goback"
                                   type="button">
                                {{ $t('Done') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
import alert from '../../views/alert.vue';
import store from '@/store'

import AppBarI18n from '@core/layouts/components/app-bar/AppBarI18n.vue'
import AppBarThemeSwitcher from '@core/layouts/components/app-bar/AppBarThemeSwitcher.vue'
import sharedServices from '@/services/shared-services'
import soapServices from '@/services/soap-services';
import moment from 'moment'
import BackAction from '@/@core/BackAction.vue'
import { DeviceUUID } from 'device-uuid'
import countries from '../../@core/utils/countries'
import AppHeader from '../test/AppHeader.vue';
import soapErrorMessages from '@/@core/utils/soap-error-messages';

import { mdiUploadOutline } from '@mdi/js';

import { getAnalytics, logEvent } from "firebase/analytics";

export default {
    components: {
        AppBarI18n,
        AppBarThemeSwitcher,
        alert,
        AppHeader,
        BackAction
    },

    data: () => ({
        analytics: getAnalytics(),
        mdiUpload: mdiUploadOutline,
        isSaudiIqama: false,
        loginForm: null,
        activePicker: null,
        valid: false,
        isDragging: false,
        state: 1,
        cardMaterial: null,
        anualFee: null,
        lastName: null,
        firstName: null,
        isRequired: true,
        image: null,
        image2: null,
        familyId: null,
        title: null,
        blackCard: false,
        idNumber: null,
        genders: [{ id: 'Male', value: 'Male' }, { id: 'Female', value: 'Female' }],
        gendersAr: [{ id: 'Male', value: 'ذكر' }, { id: 'Female', value: 'أنثى' }],
        passportNumber: null,
        fullName: null,
        additionalCard: null,
        nationality: null,
        mobileNumber: null,
        email: null,
        selectedCard: null,
        supplementaryFile: null,
        leagalId: null,
        fileData1: null,
        fileData2: null,
        fileType1: null,
        fileType2: null,
        countryShow: false,
        before16Years: null,
        date1: null,
        gender: null,
        wifeId: false,
        selectedLanguage: 'en',
        text: 'Back',
        output: false,
        MaxLimit: '0',

        selectTitle: [
            v => !!v || 'Please Select Title'
        ],
        file1: [
            v => !!v || 'Please Select File'
        ],
        number: [
            v => !!v || 'Personal ID/Iqama number is required'
        ],
        passport: [
            v => !!v || 'Passport is required'
        ],
        card: [
            v => !!v || 'Please Select Card'
        ],
        genderRule: [
            v => !!v || 'Please Select Gender'
        ],
        name: [
            v => !!v || 'First Name is required',
            v => /^[a-zA-Z]+$/.test(v) || 'Please enter only English letters.'
        ],
        lastname: [
            v => !!v || 'Last Name is required',
            v => /^[a-zA-Z]+$/.test(v) || 'Please enter only English letters.'
        ],
        national: [
            v => !!v || 'Please Select Nationality'
        ],
        dateRule: [
            v => !!v || 'Date is required'
        ],
        emailRule: [
            v => !!v || 'Email is required',
            v => /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(v) || 'incorrect_email_format'
        ],
        mobile: [
            v => !!v || 'Mobile Number is required',
            value => /^05\d{8}$/.test(value) || 'Please enter a  mobile number consisting of 10 digits  (05xxxxxxxx)'
        ],

        menu1: false,
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        files: [],
        items: ['Email', 'Mobile'],
        alertMessage: '',
        allCountries: countries.getCountry(),

        titles: [{ key: 'Mr', value: 'Mr' }, { key: 'Mrs', value: 'Mrs' }, { key: 'Miss', value: 'Miss' }, { key: 'Dr', value: 'Dr' }, { key: 'Eng', value: 'Eng' }],
        titlesAr: [{ key: 'Mr', value: 'السيد' }, { key: 'Mrs', value: 'السّيدة' }, { key: 'Miss', value: 'الآنسة' }, { key: 'Dr', value: 'دكتور' }, { key: 'Eng', value: 'مهندس' }],

        alertType: 'error',
        showAlert: false,

        relations: [
            { key: 'Son', name: 'Son' },
            { key: 'Daughter', name: 'Daughter' },
            { key: 'Wife', name: 'Wife' },
            { key: 'Mother', name: 'Mother' },
            { key: 'Father', name: 'Father' },
            { key: 'Other', name: 'Other' },
        ],
        relationsAr: [
            { key: 'Son', name: 'ابن' },
            { key: 'Daughter', name: 'بنت' },
            { key: 'Wife', name: 'زوجة' },
            { key: 'Mother', name: 'الأم' },
            { key: 'Father', name: 'أب' },
            { key: 'Other', name: 'آخر' },
        ],

    }),
    created()
    {
        store.subscribe((mutation) =>
        {
            if (mutation.type === 'accounts/SET_LANGUAGE')
            {
                this.selectedLanguage = mutation.payload;
            }
        });
    },
    watch:
    {
        menu1(val)
        {
            val && setTimeout(() => (this.activePicker = 'YEAR'))
        },
    },
    mounted()
    {
        this.before16Years = moment().subtract(15, 'years').format('yyyy-MM-DD');

        let lang = localStorage.getItem('language');
        if (lang == 'ar')
        {
            this.selectedLanguage = lang;
        }
        else
        {
            this.selectedLanguage = lang;
        }
        this.selectedCard = store.get('card/details');

        if (this.selectedCard.ProductCode == 'BB' || this.selectedCard.ProductCode == 'CE' || this.selectedCard.ProductCode == 'MB' || this.selectedCard.ProductCode == 'MS' || this.selectedCard.ProductCode == 'SA' || this.selectedCard.ProductCode == 'SV' || this.selectedCard.ProductCode == 'TS' || this.selectedCard.ProductCode == 'VP' || this.selectedCard.ProductCode == 'VS')
        {
            this.blackCard = true;
            this.getStyle();
        }
        else
        {
            this.blackCard = false
        }
        this.clientDetail = store.get('accounts/clientDetail');
        window.scrollTo(0, 0);
    },
    methods: {
        validateIqama()
        {
            this.isSaudiIqama = /^1/.test(this.idNumber)
        },
        getVisibleStatus()
        {
            if (this.additionalCard)
            {
                if (this.isSaudiIqama && this.wifeId)
                {
                    return true
                }
                else if (!this.isSaudiIqama)
                {
                    return true
                }
                else return false
            }
        },
        openDatePicker()
        {
            this.$refs.datePicker.isActive = true;
        },
        selectRelations()
        {
            if (this.additionalCard == 'Wife' || this.additionalCard == 'Other')
            {
                this.wifeId = true;
            }
            else
            {
                this.wifeId = false;
            }
        },
        getStyle()
        {
            return this.blackCard
                ? { color: 'white' }
                : { color: 'black' };
        },
        changeCountry()
        {
            if (this.nationality == 'Saudi Arabia')
            {
                this.countryShow = true;
                this.passportNumber = null;
                this.$refs.field.reset();
            }
            else
            {
                this.countryShow = false;
            }
        },
        submit()
        {
            let obj = {
                correlationID: Math.floor(1000 + Math.random() * 9000).toString(),
                messageStamp: moment(new Date()).format('DD/MM/yyyy HH:mm:ss'),
                sysId: new DeviceUUID().get(),
                sysAuth: store.get('requestKeys/accessToken'),
                requestorID: localStorage.getItem('userName'),
                idSeed: sharedServices.getIdSeed(),
                custNo: store.get('requestKeys/ClientCode'),
                accNo: this.selectedCard.accountNo,
                cardNo: this.selectedCard.primeCardSerno,
                userId: this.clientDetail.clientDemog.legalId,
                title: this.title ? this.title : "",
                firstName: this.firstName ? this.firstName : "",
                midName: "",
                lastName: this.lastName ? this.lastName : "",
                nationality: this.nationality ? this.nationality : "",
                idNumber: this.idNumber ? this.idNumber : this.clientDetail.clientDemog.legalId,
                dob: this.date1 ? this.date1 : "",
                gender: this.gender ? this.gender : "",
                mobile: this.mobileNumber ? this.mobileNumber : "",
                email: this.email ? this.email : "",
                sDocument: "",
                relation: this.additionalCard ? this.additionalCard : "",
                companyName: "",
                Section: "",
                BillingType: "",
                MaritalStatus: "",
                passportNumber: this.passportNumber ? this.passportNumber : "",
                ForeignID: "",
                MailAddress1: "",
                MailAddress2: "",
                MailPOBox: "",
                MailCity: "",
                MailProvince: "",
                MailZip: "",
                MailCountry: "",
                MailTel1: "",
                MailTel2: "",
                MailFax: "",
                MailMobile: "",
                MailEmail: "",
                HomeAddress1: "",
                HomeAddress2: "",
                HomePOBox: "",
                HomeCity: "",
                HomeProvince: "",
                HomeZip: "",
                HomeCountry: "",
                HomeTel1: "",
                HomeTel2: "",
                HomeFax: "",
                HomeMobile: "",
                HomeEmail: "",
                EmpID: "",
                JobTitle: "",
                YearsofService: 22,
                MonthlySalry: 2222000,
                CostCenterName: "",
                MaxLimit: this.MaxLimit, // 0, 1 if yes then 1, if no then 0
                DetailDesc: "",
                fileData: this.fileData1 ? this.fileData1 : "",
                fileType: this.fileType1 ? this.fileType1 : "",
                fileData2: this.fileData2 ? this.fileData2 : "",
                fileType2: this.fileType2 ? this.fileType2 : ""
            }
            soapServices.addSupplementary(obj).then(soapResp =>
            {
                if (soapResp?.SRNewSuppCardResponse?.SRNewSuppCardResult?.ServiceRequest?.Result?.ErrorCode == '000')
                {
                    this.state = 2;
                    this.step = 2;
                    window.scrollTo(0, 0);

                    logEvent(this.analytics, 'AMEX_CI_APPLY_SUPP_SUCCESS', {
                        content_type: 'userName',
                        content_id: store.get('requestKeys/userName')
                    })
                } else
                {
                    logEvent(this.analytics, 'AMEX_CI_APPLY_SUPP_FAILURE', {
                        content_type: 'userName',
                        content_id: store.get('requestKeys/userName')
                    })

                    let alertMessage = soapResp?.SRNewSuppCardResponse?.SRNewSuppCardResult?.ServiceRequest?.Result?.ErrorDesc;
                    let errorCode = soapResp?.SRNewSuppCardResponse?.SRNewSuppCardResult?.ServiceRequest?.Result.ErrorCode;
                    this.alertMessage = soapErrorMessages.get(errorCode, alertMessage);
                    this.alertType = 'error';
                    this.showAlert = true;
                    setTimeout(() =>
                    {
                        this.showAlert = false;
                    }, 5000)
                }
            });
            // }
        },

        uploadImg1(event)
        {
            let file = event;
            return new Promise((resolve, reject) =>
            {
                let fileSize = event.size / 1024 / 1024;

                const allowedExtensions = ['.jpg', '.png', '.pdf'];
                const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();

                let ext = fileExtension.split('.');

                this.fileType1 = ext[1];

                console.log('fileExtension', this.fileType1);

                let fileName = file.name.replace(/[^a-zA-Z ]/g, "");

                if (fileSize <= 5)
                {
                    if (allowedExtensions.includes(fileExtension))
                    {
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = () =>
                        {
                            fileExtension == '.pdf' ? this.image = 'pdf' : this.image = reader.result;

                            let base64Image = reader.result;
                            base64Image = base64Image.replace(/^data\:([^\;]+)\;base64,/gmi, '');
                            this.fileData1 = base64Image;
                        };
                    }
                    else
                    {
                        this.$refs.inputFile1.reset();
                        this.image = null;
                        this.supplementaryFile = null;
                        let alertMessage = 'Max file size: 5MB | Format: png, jpg and pdf'
                        this.alertMessage = alertMessage
                        this.alertType = 'error'
                        this.showAlert = true
                        setTimeout(() =>
                        {
                            this.showAlert = false
                        }, 5000)
                    }
                }
                else
                {
                    this.$refs.inputFile1.reset();
                    this.image = null;
                    this.supplementaryFile = null;
                    let alertMessage = 'File is too large, maximum file size is 5MB'
                    this.alertMessage = alertMessage
                    this.alertType = 'error'
                    this.showAlert = true
                    setTimeout(() =>
                    {
                        this.showAlert = false
                    }, 5000)
                }
            });
        },

        uploadImg2(event)
        {
            let file = event;
            return new Promise((resolve, reject) =>
            {

                let fileSize = event.size / 1024 / 1024;

                const allowedExtensions = ['.jpg', '.png', '.pdf'];
                const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();

                let ext = fileExtension.split('.');

                this.fileType2 = ext[1];

                console.log('fileExtension 2', this.fileType2)
                if (fileSize <= 5)
                {
                    if (allowedExtensions.includes(fileExtension))
                    {
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = () =>
                        {
                            fileExtension == '.pdf' ? this.image2 = 'pdf' : this.image2 = reader.result;

                            let base64Image = reader.result;
                            base64Image = base64Image.replace(/^data\:([^\;]+)\;base64,/gmi, '');
                            this.fileData2 = base64Image;
                        };
                    }
                    else
                    {
                        this.$refs.inputFile2.reset();
                        this.image2 = null;
                        this.familyId = null;
                        let alertMessage = 'Max file size: 5MB | Format: png, jpg and pdf'
                        this.alertMessage = alertMessage
                        this.alertType = 'error'
                        this.showAlert = true
                        setTimeout(() =>
                        {
                            this.showAlert = false
                        }, 5000)
                    }
                }
                else
                {
                    this.$refs.inputFile2.reset();
                    this.image2 = null;
                    this.familyId = null;
                    let alertMessage = 'File is too large, maximum file size is 5MB'
                    this.alertMessage = alertMessage
                    this.alertType = 'error'
                    this.showAlert = true
                    setTimeout(() =>
                    {
                        this.showAlert = false
                    }, 5000)
                }
            });
        },
        removeFile2(e)
        {
            this.$refs.inputFile2.reset();
            this.image2 = null;
            e.stopPropagation()
        },
        chooseFiles1: function ()
        {
            document.getElementById("fileUpload1").click()
        },
        chooseFiles2: function ()
        {
            document.getElementById("fileUpload2").click()
        },
        removeFile1(e)
        {
            this.$refs.inputFile1.reset();
            this.image = null;
            e.stopPropagation()
        },
        goback()
        {
            window.history.back();
            window.scrollTo(0, 0);
        },
    },
    computed: {
        isFormInvalid()
        {
            // Check if any required fields are empty
            let formvalid = false;
            if (!this.supplementaryFile)
            {
                return true
            }
            if (!this.isSaudiIqama)
            {
                if (!this.familyId)
                {
                    return true
                }
            }
            else
            {
                if ((this.additionalCard == 'Wife' || this.additionalCard == 'Other') && !this.familyId)
                {
                    return true
                }
            }
            if (this.nationality != 'Saudi Arabia' && !this.passportNumber)
            {
                return true
            }
            return !this.title || !this.firstName || !this.lastName || !this.idNumber || !this.nationality || !this.relations || !this.additionalCard || !this.gender || !this.email || !this.mobileNumber || !this.date1;
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/auth.scss';

::v-deep .v-input__slot {
    margin-bottom: 0px;
}

.text-field {
    margin: -16px 0px;
}

.drag-file {
    padding: 0px;
}

.card-name {
    border: 1px solid #E7E7E7;
    padding: 7px 15px;
}

.card-names {
    padding: 7px 15px;
}

::v-deep .v-chip:not(.v-chip-light-bg):not(.v-chip--no-color):not(.v-chip--outlined) {
    margin-bottom: 12px;
}

::v-deep .v-input .v-label {
    height: 20px;
    line-height: 13px;
    letter-spacing: normal;
}

::v-deep .v-select__selection--comma {
    margin: 7px 4px 7px 0;
    min-height: 10px;
    overflow: none !important;
    text-overflow: ellipsis;
    white-space: nowrap;
}

::v-deep .v-text-field.v-text-field--solo .v-input__append-inner,
.v-text-field.v-text-field--solo .v-input__prepend-inner {
    align-self: center;
    margin-top: -7px;
}

::v-deep .v-text-field input {
    line-height: 20px;
    padding: 8px 0 8px;
    max-width: 100%;
    min-width: 0px;
    width: 100%;
    margin-top: -7px;
}

.cross {
    background: white;
    padding: 0px 4px;
    border-radius: 1px;
    cursor: pointer;
}

.title {
    margin: -27px -13px 0px !important;
}

.title-copy {
    margin: -20px -13px 0px !important;
}

// .file-btn {
//     bottom: 52px;
//     right: -53.8%;
//     padding: 14px 33px 15px;
//     position: relative;
//     cursor: pointer;
// }

.v-input__append-outer {
    margin-top: 13px !important;
}

.drag-border {
    border: 1.5px dashed lightgray;
    border-radius: 6px;
    padding: 18px 18px;
    margin: 5px 0px 0px;
}


::v-deep .v-chip:not(.v-chip-light-bg):not(.v-chip--no-color):not(.v-chip--outlined) {
    color: #706B7A !important;
    background: #F0F0F0 !important;
}

::v-deep .v-input__prepend-outer {
    display: none;
}

::v-deep .v-application.theme--light .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
    box-shadow: none !important;
}

::v-deep .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
    /* box-shadow: 0 1px 3px 0 rgb(94 86 105 / 20%), 0 3px 1px -2px rgb(94 86 105 / 12%), 0 2px 2px 0 rgb(94 86 105 / 14%); */
    box-shadow: none !important;
}

::v-deep .v-text-field--outlined fieldset {

    height: 54px !important;
}

@media screen and (max-width: 950px) {

    .divide {
        display: none;
    }

}

@media screen and (max-width: 399px) {

    .text-color-black {
        font-size: 14px !important;
    }

    .text-blue {
        font-size: 14px !important;
    }

}

@media screen and (max-width: 600px) {

    .captcha-input {
        width: 100%;
    }

}

@media screen and (max-width: 959px) {

    .mt-1 {
        margin-top: 0px !important
    }

    .mb-1 {
        margin-bottom: 0px !important
    }

    .text-field {
        margin: 0px 0px !important;
    }

    .top-space {
        margin-top: -19px !important;
    }

    .image-upload {
        margin-top: -28px !important;

    }


}


.img {
    width: 70px !important;
}


.dropzone-container {
    padding: 4rem;
    background: #f7fafc;
    border: 1px solid #e2e8f0;
}

.file-label {
    font-size: 20px;
    display: block;
}

.preview-container {
    display: flex;
    margin-top: 2rem;
}

.preview-card {
    display: flex;
    // border: 1px solid #a2a2a2;
    // padding: 5px;
    // margin-left: 5px;
}

.text-2xl {
    font-size: 1.3rem !important;
    line-height: 2rem !important;
}

.success-message {
    width: 50%;
    margin: 0 auto;
}

.circle {
    background: #E2F1FD;
    width: 118px;
    height: 115px;
    margin: 0 auto;
    padding: 31px 0px;
    border-radius: 70px;

    img {
        width: 55px;
    }
}

@media screen and (max-width: 1000px) {
    .success-message {
        width: 100%;
        margin: 0 auto;
    }
}

::v-deep .v-select .v-select__selections {
    padding-bottom: 7px !important;
}
</style>
