let allCountries = [{
    id: 4,
    ar: "أفغانستان",
    en: "Afghanistan",
},
{
    id: 8,
    ar: "ألبانيا",
    en: "Albania",
},
{
    id: 12,
    ar: "الجزائر",
    en: "Algeria",
},
{
    id: 20,
    ar: "أندورا",
    en: "Andorra",
},
{
    id: 24,
    ar: "أنغولا",
    en: "Angola",
},
{
    id: 28,
    ar: "أنتيغوا وباربودا",
    en: "Antigua and Barbuda",
},
{
    id: 32,
    ar: "الأرجنتين",
    en: "Argentina",
},
{
    id: 51,
    ar: "أرمينيا",
    en: "Armenia",
},
{
    id: 36,
    ar: "أستراليا",
    en: "Australia",
},
{
    id: 40,
    ar: "النمسا",
    en: "Austria",
},
{
    id: 31,
    ar: "أذربيجان",
    en: "Azerbaijan",
},
{
    id: 44,
    ar: "باهاماس",
    en: "Bahamas",
},
{
    id: 48,
    ar: "البحرين",
    en: "Bahrain",
},
{
    id: 50,
    ar: "بنغلاديش",
    en: "Bangladesh",
},
{
    id: 52,
    ar: "باربادوس",
    en: "Barbados",
},
{
    id: 112,
    ar: "بيلاروس",
    en: "Belarus",
},
{
    id: 56,
    ar: "بلجيكا",
    en: "Belgium",
},
{
    id: 84,
    ar: "بليز",
    en: "Belize",
},
{
    id: 204,
    ar: "بنين",
    en: "Benin",
},
{
    id: 64,
    ar: "بوتان",
    en: "Bhutan",
},
{
    id: 68,
    ar: "بوليفيا",
    en: "Bolivia (Plurinational State of)",
},
{
    id: 70,
    ar: "البوسنة والهرسك",
    en: "Bosnia and Herzegovina",
},
{
    id: 72,
    ar: "بوتسوانا",
    en: "Botswana",
},
{
    id: 76,
    ar: "البرازيل",
    en: "Brazil",
},
{
    id: 96,
    ar: "بروناي",
    en: "Brunei Darussalam",
},
{
    id: 100,
    ar: "بلغاريا",
    en: "Bulgaria",
},
{
    id: 854,
    ar: "بوركينا فاسو",
    en: "Burkina Faso",
},
{
    id: 108,
    ar: "بوروندي",
    en: "Burundi",
},
{
    id: 116,
    ar: "كمبوديا",
    en: "Cambodia",
},
{
    id: 120,
    ar: "الكاميرون",
    en: "Cameroon",
},
{
    id: 124,
    ar: "كندا",
    en: "Canada",
},
{
    id: 132,
    ar: "الرأس الأخضر",
    en: "Cabo Verde",
},
{
    id: 140,
    ar: "جمهورية أفريقيا الوسطى",
    en: "Central African Republic",
},
{
    id: 148,
    ar: "تشاد",
    en: "Chad",
},
{
    id: 152,
    ar: "تشيلي",
    en: "Chile",
},
{
    id: 156,
    ar: "الصين",
    en: "China",
},
{
    id: 170,
    ar: "كولومبيا",
    en: "Colombia",
},
{
    id: 174,
    ar: "جزر القمر",
    en: "Comoros",
},
{
    id: 178,
    ar: "جمهورية الكونغو",
    en: "Congo",
},
{
    id: 180,
    ar: "جمهورية الكونغو الديمقراطية",
    en: "Congo, Democratic Republic of the",
},
{
    id: 188,
    ar: "كوستاريكا",
    en: "Costa Rica",
},
{
    id: 384,
    ar: "ساحل العاج",
    en: "Côte d'Ivoire",
},
{
    id: 191,
    ar: "كرواتيا",
    en: "Croatia",
},
{
    id: 192,
    ar: "كوبا",
    en: "Cuba",
},
{
    id: 196,
    ar: "قبرص",
    en: "Cyprus",
},
{
    id: 203,
    ar: "جمهورية التشيك",
    en: "Czechia",
},
{
    id: 208,
    ar: "الدنمارك",
    en: "Denmark",
},
{
    id: 262,
    ar: "جيبوتي",
    en: "Djibouti",
},
{
    id: 212,
    ar: "دومينيكا",
    en: "Dominica",
},
{
    id: 214,
    ar: "جمهورية الدومينيكان",
    en: "Dominican Republic",
},
{
    id: 218,
    ar: "الإكوادور",
    en: "Ecuador",
},
{
    id: 818,
    ar: "مصر",
    en: "Egypt",
},
{
    id: 222,
    ar: "السلفادور",
    en: "El Salvador",
},
{
    id: 226,
    ar: "غينيا الاستوائية",
    en: "Equatorial Guinea",
},
{
    id: 232,
    ar: "إريتريا",
    en: "Eritrea",
},
{
    id: 233,
    ar: "إستونيا",
    en: "Estonia",
},
{
    id: 231,
    ar: "إثيوبيا",
    en: "Ethiopia",
},
{
    id: 242,
    ar: "فيجي",
    en: "Fiji",
},
{
    id: 246,
    ar: "فنلندا",
    en: "Finland",
},
{
    id: 250,
    ar: "فرنسا",
    en: "France",
},
{
    id: 266,
    ar: "الغابون",
    en: "Gabon",
},
{
    id: 270,
    ar: "غامبيا",
    en: "Gambia",
},
{
    id: 268,
    ar: "جورجيا",
    en: "Georgia",
},
{
    id: 276,
    ar: "ألمانيا",
    en: "Germany",
},
{
    id: 288,
    ar: "غانا",
    en: "Ghana",
},
{
    id: 300,
    ar: "اليونان",
    en: "Greece",
},
{
    id: 308,
    ar: "غرينادا",
    en: "Grenada",
},
{
    id: 320,
    ar: "غواتيمالا",
    en: "Guatemala",
},
{
    id: 324,
    ar: "غينيا",
    en: "Guinea",
},
{
    id: 624,
    ar: "غينيا بيساو",
    en: "Guinea-Bissau",
},
{
    id: 328,
    ar: "غيانا",
    en: "Guyana",
},
{
    id: 332,
    ar: "هايتي",
    en: "Haiti",
},
{
    id: 340,
    ar: "هندوراس",
    en: "Honduras",
},
{
    id: 348,
    ar: "المجر",
    en: "Hungary",
},
{
    id: 352,
    ar: "آيسلندا",
    en: "Iceland",
},
{
    id: 356,
    ar: "الهند",
    en: "India",
},
{
    id: 360,
    ar: "إندونيسيا",
    en: "Indonesia",
},
{
    id: 364,
    ar: "إيران",
    en: "Iran (Islamic Republic of)",
},
{
    id: 368,
    ar: "العراق",
    en: "Iraq",
},
{
    id: 372,
    ar: "أيرلندا",
    en: "Ireland",
},
{
    id: 376,
    ar: "إسرائيل",
    en: "Israel",
},
{
    id: 380,
    ar: "إيطاليا",
    en: "Italy",
},
{
    id: 388,
    ar: "جامايكا",
    en: "Jamaica",
},
{
    id: 392,
    ar: "اليابان",
    en: "Japan",
},
{
    id: 400,
    ar: "الأردن",
    en: "Jordan",
},
{
    id: 398,
    ar: "كازاخستان",
    en: "Kazakhstan",
},
{
    id: 404,
    ar: "كينيا",
    en: "Kenya",
},
{
    id: 296,
    ar: "كيريباتي",
    en: "Kiribati",
},
{
    id: 408,
    ar: "كوريا الشمالية",
    en: "Korea (Democratic People's Republic of)",
},
{
    id: 410,
    ar: "كوريا الجنوبية",
    en: "Korea, Republic of",
},
{
    id: 414,
    ar: "الكويت",
    en: "Kuwait",
},
{
    id: 417,
    ar: "قيرغيزستان",
    en: "Kyrgyzstan",
},
{
    id: 418,
    ar: "لاوس",
    en: "Lao People's Democratic Republic",
},
{
    id: 428,
    ar: "لاتفيا",
    en: "Latvia",
},
{
    id: 422,
    ar: "لبنان",
    en: "Lebanon",
},
{
    id: 426,
    ar: "ليسوتو",
    en: "Lesotho",
},
{
    id: 430,
    ar: "ليبيريا",
    en: "Liberia",
},
{
    id: 434,
    ar: "ليبيا",
    en: "Libya",
},
{
    id: 438,
    ar: "ليختنشتاين",
    en: "Liechtenstein",
},
{
    id: 440,
    ar: "ليتوانيا",
    en: "Lithuania",
},
{
    id: 442,
    ar: "لوكسمبورغ",
    en: "Luxembourg",
},
{
    id: 807,
    ar: "مقدونيا",
    en: "North Macedonia",
},
{
    id: 450,
    ar: "مدغشقر",
    en: "Madagascar",
},
{
    id: 454,
    ar: "مالاوي",
    en: "Malawi",
},
{
    id: 458,
    ar: "ماليزيا",
    en: "Malaysia",
},
{
    id: 462,
    ar: "جزر المالديف",
    en: "Maldives",
},
{
    id: 466,
    ar: "مالي",
    en: "Mali",
},
{
    id: 470,
    ar: "مالطا",
    en: "Malta",
},
{
    id: 584,
    ar: "جزر مارشال",
    en: "Marshall Islands",
},
{
    id: 478,
    ar: "موريتانيا",
    en: "Mauritania",
},
{
    id: 480,
    ar: "موريشيوس",
    en: "Mauritius",
},
{
    id: 484,
    ar: "المكسيك",
    en: "Mexico",
},
{
    id: 583,
    ar: "ولايات ميكرونيسيا المتحدة",
    en: "Micronesia (Federated States of)",
},
{
    id: 504,
    ar: "المغرب",
    en: "Morocco",
},
{
    id: 498,
    ar: "مولدوفا",
    en: "Moldova, Republic of",
},
{
    id: 492,
    ar: "موناكو",
    en: "Monaco",
},
{
    id: 496,
    ar: "منغوليا",
    en: "Mongolia",
},
{
    id: 499,
    ar: "الجبل الأسود",
    en: "Montenegro",
},
{
    id: 508,
    ar: "موزمبيق",
    en: "Mozambique",
},
{
    id: 104,
    ar: "ميانمار",
    en: "Myanmar",
},
{
    id: 516,
    ar: "ناميبيا",
    en: "Namibia",
},
{
    id: 520,
    ar: "ناورو",
    en: "Nauru",
},
{
    id: 524,
    ar: "نيبال",
    en: "Nepal",
},
{
    id: 528,
    ar: "هولندا",
    en: "Netherlands",
},
{
    id: 554,
    ar: "نيوزيلندا",
    en: "New Zealand",
},
{
    id: 558,
    ar: "نيكاراغوا",
    en: "Nicaragua",
},
{
    id: 562,
    ar: "النيجر",
    en: "Niger",
},
{
    id: 566,
    ar: "نيجيريا",
    en: "Nigeria",
},
{
    id: 578,
    ar: "النرويج",
    en: "Norway",
},
{
    id: 512,
    ar: "عمان",
    en: "Oman",
},
{
    id: 586,
    ar: "باكستان",
    en: "Pakistan",
},
{
    id: 585,
    ar: "بالاو",
    en: "Palau",
},
{
    id: 591,
    ar: "بنما",
    en: "Panama",
},
{
    id: 598,
    ar: "بابوا غينيا الجديدة",
    en: "Papua New Guinea",
},
{
    id: 600,
    ar: "باراغواي",
    en: "Paraguay",
},
{
    id: 604,
    ar: "بيرو",
    en: "Peru",
},
{
    id: 608,
    ar: "الفلبين",
    en: "Philippines",
},
{
    id: 616,
    ar: "بولندا",
    en: "Poland",
},
{
    id: 620,
    ar: "البرتغال",
    en: "Portugal",
},
{
    id: 634,
    ar: "قطر",
    en: "Qatar",
},
{
    id: 642,
    ar: "رومانيا",
    en: "Romania",
},
{
    id: 643,
    ar: "روسيا",
    en: "Russian Federation",
},
{
    id: 646,
    ar: "رواندا",
    en: "Rwanda",
},
{
    id: 659,
    ar: "سانت كيتس ونيفيس",
    en: "Saint Kitts and Nevis",
},
{
    id: 662,
    ar: "سانت لوسيا",
    en: "Saint Lucia",
},
{
    id: 670,
    ar: "سانت فينسنت والغرينادين",
    en: "Saint Vincent and the Grenadines",
},
{
    id: 882,
    ar: "ساموا",
    en: "Samoa",
},
{
    id: 674,
    ar: "سان مارينو",
    en: "San Marino",
},
{
    id: 678,
    ar: "ساو تومي وبرينسيب",
    en: "Sao Tome and Principe",
},
{
    id: 682,
    ar: "السعودية",
    en: "Saudi Arabia",
},
{
    id: 686,
    ar: "السنغال",
    en: "Senegal",
},
{
    id: 688,
    ar: "صربيا",
    en: "Serbia",
},
{
    id: 690,
    ar: "سيشل",
    en: "Seychelles",
},
{
    id: 694,
    ar: "سيراليون",
    en: "Sierra Leone",
},
{
    id: 702,
    ar: "سنغافورة",
    en: "Singapore",
},
{
    id: 703,
    ar: "سلوفاكيا",
    en: "Slovakia",
},
{
    id: 705,
    ar: "سلوفينيا",
    en: "Slovenia",
},
{
    id: 90,
    ar: "جزر سليمان",
    en: "Solomon Islands",
},
{
    id: 706,
    ar: "الصومال",
    en: "Somalia",
},
{
    id: 710,
    ar: "جنوب أفريقيا",
    en: "South Africa",
},
{
    id: 728,
    ar: "جنوب السودان",
    en: "South Sudan",
},
{
    id: 724,
    ar: "إسبانيا",
    en: "Spain",
},
{
    id: 144,
    ar: "سريلانكا",
    en: "Sri Lanka",
},
{
    id: 729,
    ar: "السودان",
    en: "Sudan",
},
{
    id: 740,
    ar: "سورينام",
    en: "Suriname",
},
{
    id: 748,
    ar: "إسواتيني",
    en: "Eswatini",
},
{
    id: 752,
    ar: "السويد",
    en: "Sweden",
},
{
    id: 756,
    ar: "سويسرا",
    en: "Switzerland",
},
{
    id: 760,
    ar: "سوريا",
    en: "Syrian Arab Republic",
},
{
    id: 762,
    ar: "طاجيكستان",
    en: "Tajikistan",
},
{
    id: 834,
    ar: "تنزانيا",
    en: "Tanzania, United Republic of",
},
{
    id: 764,
    ar: "تايلاند",
    en: "Thailand",
},
{
    id: 626,
    ar: "تيمور الشرقية",
    en: "Timor-Leste",
},
{
    id: 768,
    ar: "توغو",
    en: "Togo",
},
{
    id: 776,
    ar: "تونغا",
    en: "Tonga",
},
{
    id: 780,
    ar: "ترينيداد وتوباغو",
    en: "Trinidad and Tobago",
},
{
    id: 788,
    ar: "تونس",
    en: "Tunisia",
},
{
    id: 792,
    ar: "تركيا",
    en: "Türkiye",
},
{
    id: 795,
    ar: "تركمانستان",
    en: "Turkmenistan",
},
{
    id: 798,
    ar: "توفالو",
    en: "Tuvalu",
},
{
    id: 800,
    ar: "أوغندا",
    en: "Uganda",
},
{
    id: 804,
    ar: "أوكرانيا",
    en: "Ukraine",
},
{
    id: 784,
    ar: "الإمارات العربية المتحدة",
    en: "United Arab Emirates",
},
{
    id: 826,
    ar: "المملكة المتحدة",
    en: "United Kingdom of Great Britain and Northern Ireland",
},
{
    id: 840,
    ar: "الولايات المتحدة",
    en: "United States of America",
},
{
    id: 858,
    ar: "الأوروغواي",
    en: "Uruguay",
},
{
    id: 860,
    ar: "أوزبكستان",
    en: "Uzbekistan",
},
{
    id: 548,
    ar: "فانواتو",
    en: "Vanuatu",
},
{
    id: 862,
    ar: "فنزويلا",
    en: "Venezuela (Bolivarian Republic of)",
},
{
    id: 704,
    ar: "فيتنام",
    en: "Viet Nam",
},
{
    id: 887,
    ar: "اليمن",
    en: "Yemen",
},
{
    id: 894,
    ar: "زامبيا",
    en: "Zambia",
},
{
    id: 716,
    ar: "زيمبابوي",
    en: "Zimbabwe",
}]
export const getCountry = () =>
{
    return allCountries
}
export const getSingleCountry = (val) =>
{

    for (let i = 0; i < allCountries.length; i++)
    {
        if (val == allCountries[i].name)
        {
            return allCountries[i].flag
        }

    }
}

export const getArabicSingleCountry = (val) =>
{

    for (let i = 0; i < allCountries.length; i++)
    {
        if (val == allCountries[i].name)
        {
            return allCountries[i].name_ar
        }

    }
}

export const getArCountry = (val) =>
{

    for (let i = 0; i < allCountries.length; i++)
    {
        if (val == allCountries[i].name)
        {
            return allCountries[i].name_ar
        }

    }
}
export default {
    getCountry,
    getSingleCountry,
    getArCountry,
    getArabicSingleCountry
}